// extracted by mini-css-extract-plugin
export var btnDefault = "services-module--btn-default--6WElr";
export var attoiconJobs = "services-module--attoicon-jobs--ogqRd";
export var attoiconNotes = "services-module--attoicon-notes--2nAkE";
export var attoiconTimesheets = "services-module--attoicon-timesheets--InFqi";
export var attoiconTimesheetsRounded1 = "services-module--attoicon-timesheets-rounded1--iTaWg";
export var attoiconMobileTt = "services-module--attoicon-mobile-tt--hfY7V";
export var attoiconNamedSites = "services-module--attoicon-named-sites--srTme";
export var attoiconProfitableBids = "services-module--attoicon-profitable-bids--JjQ6v";
export var attoiconDepartments = "services-module--attoicon-departments--++gBb";
export var attoiconTimeline = "services-module--attoicon-timeline--VHmbq";
export var attoiconFilters = "services-module--attoicon-filters---NO3x";
export var attoiconVerified = "services-module--attoicon-verified--iKOlD";
export var attoiconTimezone = "services-module--attoicon-timezone--nxHH1";
export var attoiconManualEntries = "services-module--attoicon-manual-entries--d6ANB";
export var attoiconJobCosting = "services-module--attoicon-job-costing--YjAnH";
export var attoiconLocationTracking = "services-module--attoicon-location-tracking--o+adD";
export var attoiconBreak = "services-module--attoicon-break--khpA9";
export var attoiconNightShift = "services-module--attoicon-night-shift--AptBW";
export var attoiconOvertime = "services-module--attoicon-overtime--pRfYL";
export var attoiconTimeTracking1 = "services-module--attoicon-time-tracking1--QAIAA";
export var attoiconTimeTracking = "services-module--attoicon-time-tracking--NhbcA";
export var attoiconLocationHistory = "services-module--attoicon-location-history--LZuot";
export var attoiconPrivacy = "services-module--attoicon-privacy---dHOU";
export var attoiconTimeEntryHistory = "services-module--attoicon-time-entry-history--IBxXB";
export var attoiconCustomize = "services-module--attoicon-customize--LIR+U";
export var attoiconRoundedClock = "services-module--attoicon-rounded-clock--CPzGZ";
export var attoiconTimeOff = "services-module--attoicon-time-off--uQ5wr";
export var attoiconNamedLocations = "services-module--attoicon-named-locations--8jwrJ";
export var attoiconSandClock = "services-module--attoicon-sand-clock--Gce64";
export var attoiconBattery = "services-module--attoicon-battery--uETJ3";
export var attoiconSupport = "services-module--attoicon-support--Di2BE";
export var attoiconHelpSupport = "services-module--attoicon-help-support--JVowj";
export var attoiconWebTt = "services-module--attoicon-web-tt--AK9H+";
export var attoiconArchive = "services-module--attoicon-archive--MDqy7";
export var attoiconEmail = "services-module--attoicon-email--zVL6W";
export var attoiconKiosk = "services-module--attoicon-kiosk--mA1Yo";
export var attoiconShare = "services-module--attoicon-share--W3mCL";
export var attoiconCrew = "services-module--attoicon-crew--5sj5z";
export var attoiconTeamActivity = "services-module--attoicon-team-activity--4f7eD";
export var attoiconTeam = "services-module--attoicon-team--qL6wr";
export var attoiconWages = "services-module--attoicon-wages--pLoAv";
export var attoiconNotification = "services-module--attoicon-notification--RawCj";
export var attoiconAvatar = "services-module--attoicon-avatar--D-gRP";
export var attoiconViewMap = "services-module--attoicon-view-map--yDliq";
export var attoiconMovementTracking = "services-module--attoicon-movement-tracking--Iurj5";
export var attoiconWageEstimates = "services-module--attoicon-wage-estimates--MEAPt";
export var attoiconWageEstimatesBold = "services-module--attoicon-wage-estimates-bold--pebag";
export var attoiconClose = "services-module--attoicon-close--kQ6T1";
export var attoiconPlus = "services-module--attoicon-plus--AjfAw";
export var attoiconMinus = "services-module--attoicon-minus--CZqSi";
export var attoiconTick = "services-module--attoicon-tick--ZL6ba";
export var attoiconArrowLeft = "services-module--attoicon-arrow-left--mO9q4";
export var attoiconArrowRight = "services-module--attoicon-arrow-right--QusS+";
export var attoiconArrowDown = "services-module--attoicon-arrow-down--Tirvk";
export var attoiconArrowUp = "services-module--attoicon-arrow-up--25sc6";
export var attoiconPlay = "services-module--attoicon-play--vS6x1";
export var attoiconLongArrow = "services-module--attoicon-long-arrow--vbA0-";
export var container = "services-module--container--VQvTS";
export var serviceList = "services-module--serviceList--h11cp";