// extracted by mini-css-extract-plugin
export var btnDefault = "feature-tabs-module--btn-default--S182O";
export var attoiconJobs = "feature-tabs-module--attoicon-jobs--WA3he";
export var attoiconNotes = "feature-tabs-module--attoicon-notes--H0-CS";
export var attoiconTimesheets = "feature-tabs-module--attoicon-timesheets--uQXn7";
export var attoiconTimesheetsRounded1 = "feature-tabs-module--attoicon-timesheets-rounded1--t6kw5";
export var attoiconMobileTt = "feature-tabs-module--attoicon-mobile-tt--m8XpA";
export var attoiconNamedSites = "feature-tabs-module--attoicon-named-sites--Gwg4j";
export var attoiconProfitableBids = "feature-tabs-module--attoicon-profitable-bids--tAH0f";
export var attoiconDepartments = "feature-tabs-module--attoicon-departments--36vT3";
export var attoiconTimeline = "feature-tabs-module--attoicon-timeline--I1vqL";
export var attoiconFilters = "feature-tabs-module--attoicon-filters--RnLEs";
export var attoiconVerified = "feature-tabs-module--attoicon-verified--k8Tig";
export var attoiconTimezone = "feature-tabs-module--attoicon-timezone--Btsog";
export var attoiconManualEntries = "feature-tabs-module--attoicon-manual-entries--NHpw9";
export var attoiconJobCosting = "feature-tabs-module--attoicon-job-costing--XeBjC";
export var attoiconLocationTracking = "feature-tabs-module--attoicon-location-tracking--nMFse";
export var attoiconBreak = "feature-tabs-module--attoicon-break--uqO+S";
export var attoiconNightShift = "feature-tabs-module--attoicon-night-shift--py3GL";
export var attoiconOvertime = "feature-tabs-module--attoicon-overtime--VuZHy";
export var attoiconTimeTracking1 = "feature-tabs-module--attoicon-time-tracking1--QjGL7";
export var attoiconTimeTracking = "feature-tabs-module--attoicon-time-tracking--Xb9ha";
export var attoiconLocationHistory = "feature-tabs-module--attoicon-location-history--EVcjv";
export var attoiconPrivacy = "feature-tabs-module--attoicon-privacy--xAjP+";
export var attoiconTimeEntryHistory = "feature-tabs-module--attoicon-time-entry-history--qdlS7";
export var attoiconCustomize = "feature-tabs-module--attoicon-customize--EOegg";
export var attoiconRoundedClock = "feature-tabs-module--attoicon-rounded-clock--ePq+n";
export var attoiconTimeOff = "feature-tabs-module--attoicon-time-off--gubeJ";
export var attoiconNamedLocations = "feature-tabs-module--attoicon-named-locations--m1TBg";
export var attoiconSandClock = "feature-tabs-module--attoicon-sand-clock--3I04z";
export var attoiconBattery = "feature-tabs-module--attoicon-battery--ULuty";
export var attoiconSupport = "feature-tabs-module--attoicon-support--HePor";
export var attoiconHelpSupport = "feature-tabs-module--attoicon-help-support--1g05p";
export var attoiconWebTt = "feature-tabs-module--attoicon-web-tt--AUmhI";
export var attoiconArchive = "feature-tabs-module--attoicon-archive--EnDyw";
export var attoiconEmail = "feature-tabs-module--attoicon-email--80Drt";
export var attoiconKiosk = "feature-tabs-module--attoicon-kiosk--hzOfL";
export var attoiconShare = "feature-tabs-module--attoicon-share--GNkYk";
export var attoiconCrew = "feature-tabs-module--attoicon-crew--ncIq9";
export var attoiconTeamActivity = "feature-tabs-module--attoicon-team-activity--i53-a";
export var attoiconTeam = "feature-tabs-module--attoicon-team--ODX1y";
export var attoiconWages = "feature-tabs-module--attoicon-wages--gzLSA";
export var attoiconNotification = "feature-tabs-module--attoicon-notification--IpAQ2";
export var attoiconAvatar = "feature-tabs-module--attoicon-avatar--LLbg2";
export var attoiconViewMap = "feature-tabs-module--attoicon-view-map--vMLHt";
export var attoiconMovementTracking = "feature-tabs-module--attoicon-movement-tracking--JwcW9";
export var attoiconWageEstimates = "feature-tabs-module--attoicon-wage-estimates--Cmo1o";
export var attoiconWageEstimatesBold = "feature-tabs-module--attoicon-wage-estimates-bold--GrTlB";
export var attoiconClose = "feature-tabs-module--attoicon-close--MRw4u";
export var attoiconPlus = "feature-tabs-module--attoicon-plus--+sPlB";
export var attoiconMinus = "feature-tabs-module--attoicon-minus--Nq+hE";
export var attoiconTick = "feature-tabs-module--attoicon-tick--vZqP2";
export var attoiconArrowLeft = "feature-tabs-module--attoicon-arrow-left--0tNOY";
export var attoiconArrowRight = "feature-tabs-module--attoicon-arrow-right--TQTen";
export var attoiconArrowDown = "feature-tabs-module--attoicon-arrow-down--eiHwH";
export var attoiconArrowUp = "feature-tabs-module--attoicon-arrow-up--9Amn+";
export var attoiconPlay = "feature-tabs-module--attoicon-play--Xa3OP";
export var attoiconLongArrow = "feature-tabs-module--attoicon-long-arrow--hTSlu";
export var container = "feature-tabs-module--container--up0Kr";
export var content = "feature-tabs-module--content--ja9IW";
export var inactive = "feature-tabs-module--inactive--rkM7d";
export var collapseDiv = "feature-tabs-module--collapseDiv--lpmRa";
export var triggerItemWrap = "feature-tabs-module--triggerItemWrap--gZAnL";