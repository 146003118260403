// extracted by mini-css-extract-plugin
export var btnDefault = "homepage-module--btn-default--+ppqu";
export var attoiconJobs = "homepage-module--attoicon-jobs--CZQ70";
export var attoiconNotes = "homepage-module--attoicon-notes--3NmcJ";
export var attoiconTimesheets = "homepage-module--attoicon-timesheets--Cyu0O";
export var attoiconTimesheetsRounded1 = "homepage-module--attoicon-timesheets-rounded1--vnsRq";
export var attoiconMobileTt = "homepage-module--attoicon-mobile-tt--CgeF3";
export var attoiconNamedSites = "homepage-module--attoicon-named-sites--2+PlB";
export var attoiconProfitableBids = "homepage-module--attoicon-profitable-bids--+rjKl";
export var attoiconDepartments = "homepage-module--attoicon-departments--d2bXl";
export var attoiconTimeline = "homepage-module--attoicon-timeline--FWmt2";
export var attoiconFilters = "homepage-module--attoicon-filters--9dl7v";
export var attoiconVerified = "homepage-module--attoicon-verified--LjSNM";
export var attoiconTimezone = "homepage-module--attoicon-timezone--pD-yz";
export var attoiconManualEntries = "homepage-module--attoicon-manual-entries--NzHx6";
export var attoiconJobCosting = "homepage-module--attoicon-job-costing--ViCzi";
export var attoiconLocationTracking = "homepage-module--attoicon-location-tracking--0t3Ss";
export var attoiconBreak = "homepage-module--attoicon-break--CEdkp";
export var attoiconNightShift = "homepage-module--attoicon-night-shift--4SQxR";
export var attoiconOvertime = "homepage-module--attoicon-overtime--wqKpO";
export var attoiconTimeTracking1 = "homepage-module--attoicon-time-tracking1--N2dmz";
export var attoiconTimeTracking = "homepage-module--attoicon-time-tracking--lU-KY";
export var attoiconLocationHistory = "homepage-module--attoicon-location-history--j8EBT";
export var attoiconPrivacy = "homepage-module--attoicon-privacy--55l8E";
export var attoiconTimeEntryHistory = "homepage-module--attoicon-time-entry-history--Z4m8O";
export var attoiconCustomize = "homepage-module--attoicon-customize--URswJ";
export var attoiconRoundedClock = "homepage-module--attoicon-rounded-clock--MFJ0L";
export var attoiconTimeOff = "homepage-module--attoicon-time-off--4Pzo5";
export var attoiconNamedLocations = "homepage-module--attoicon-named-locations--pQzT3";
export var attoiconSandClock = "homepage-module--attoicon-sand-clock--YiXDf";
export var attoiconBattery = "homepage-module--attoicon-battery--myfMy";
export var attoiconSupport = "homepage-module--attoicon-support--QoPV3";
export var attoiconHelpSupport = "homepage-module--attoicon-help-support--EENCu";
export var attoiconWebTt = "homepage-module--attoicon-web-tt--JjGTy";
export var attoiconArchive = "homepage-module--attoicon-archive--GE5WF";
export var attoiconEmail = "homepage-module--attoicon-email--jEsJ9";
export var attoiconKiosk = "homepage-module--attoicon-kiosk--EP-QY";
export var attoiconShare = "homepage-module--attoicon-share--iDTkS";
export var attoiconCrew = "homepage-module--attoicon-crew--Yd185";
export var attoiconTeamActivity = "homepage-module--attoicon-team-activity--buL42";
export var attoiconTeam = "homepage-module--attoicon-team--AiSE7";
export var attoiconWages = "homepage-module--attoicon-wages--R8CGu";
export var attoiconNotification = "homepage-module--attoicon-notification--ZT-5u";
export var attoiconAvatar = "homepage-module--attoicon-avatar--R2Shv";
export var attoiconViewMap = "homepage-module--attoicon-view-map--fvy8e";
export var attoiconMovementTracking = "homepage-module--attoicon-movement-tracking---sE7m";
export var attoiconWageEstimates = "homepage-module--attoicon-wage-estimates--7N36M";
export var attoiconWageEstimatesBold = "homepage-module--attoicon-wage-estimates-bold--8drwi";
export var attoiconClose = "homepage-module--attoicon-close--g3qt-";
export var attoiconPlus = "homepage-module--attoicon-plus--Ej56W";
export var attoiconMinus = "homepage-module--attoicon-minus--eyVnj";
export var attoiconTick = "homepage-module--attoicon-tick--eDCML";
export var attoiconArrowLeft = "homepage-module--attoicon-arrow-left--hFKey";
export var attoiconArrowRight = "homepage-module--attoicon-arrow-right--kN4PT";
export var attoiconArrowDown = "homepage-module--attoicon-arrow-down--Vs-2P";
export var attoiconArrowUp = "homepage-module--attoicon-arrow-up--x0Mr-";
export var attoiconPlay = "homepage-module--attoicon-play--UT5f8";
export var attoiconLongArrow = "homepage-module--attoicon-long-arrow--yFaAl";
export var sliderWrapper = "homepage-module--sliderWrapper--qBN2u";
export var sliderText = "homepage-module--sliderText--IFq0z";
export var numbers = "homepage-module--numbers--OVVzV";
export var mobileImageWrapper = "homepage-module--mobileImageWrapper--XQ-yt";
export var mobileImage = "homepage-module--mobileImage--qM7X7";
export var desktopImage = "homepage-module--desktopImage--1YQWJ";