// extracted by mini-css-extract-plugin
export var btnDefault = "tabs-module--btn-default--n+aSS";
export var attoiconJobs = "tabs-module--attoicon-jobs--S7vZW";
export var attoiconNotes = "tabs-module--attoicon-notes--I4yRh";
export var attoiconTimesheets = "tabs-module--attoicon-timesheets--oA8DY";
export var attoiconTimesheetsRounded1 = "tabs-module--attoicon-timesheets-rounded1--apfzy";
export var attoiconMobileTt = "tabs-module--attoicon-mobile-tt--SiL7C";
export var attoiconNamedSites = "tabs-module--attoicon-named-sites--sm6IK";
export var attoiconProfitableBids = "tabs-module--attoicon-profitable-bids--onR4X";
export var attoiconDepartments = "tabs-module--attoicon-departments--KqTw-";
export var attoiconTimeline = "tabs-module--attoicon-timeline--zh8XK";
export var attoiconFilters = "tabs-module--attoicon-filters--6Z0gg";
export var attoiconVerified = "tabs-module--attoicon-verified--gbNoK";
export var attoiconTimezone = "tabs-module--attoicon-timezone--KTqOR";
export var attoiconManualEntries = "tabs-module--attoicon-manual-entries--iqiYC";
export var attoiconJobCosting = "tabs-module--attoicon-job-costing--CzwHq";
export var attoiconLocationTracking = "tabs-module--attoicon-location-tracking--drke+";
export var attoiconBreak = "tabs-module--attoicon-break--mesjP";
export var attoiconNightShift = "tabs-module--attoicon-night-shift--pDKAH";
export var attoiconOvertime = "tabs-module--attoicon-overtime--jfd3B";
export var attoiconTimeTracking1 = "tabs-module--attoicon-time-tracking1--aaNUL";
export var attoiconTimeTracking = "tabs-module--attoicon-time-tracking--2rrx1";
export var attoiconLocationHistory = "tabs-module--attoicon-location-history--bzEEf";
export var attoiconPrivacy = "tabs-module--attoicon-privacy--4VGiR";
export var attoiconTimeEntryHistory = "tabs-module--attoicon-time-entry-history--90WfI";
export var attoiconCustomize = "tabs-module--attoicon-customize--tgGrv";
export var attoiconRoundedClock = "tabs-module--attoicon-rounded-clock--zfaLA";
export var attoiconTimeOff = "tabs-module--attoicon-time-off--C-+XK";
export var attoiconNamedLocations = "tabs-module--attoicon-named-locations--43bOo";
export var attoiconSandClock = "tabs-module--attoicon-sand-clock--4dqkZ";
export var attoiconBattery = "tabs-module--attoicon-battery--9fNdA";
export var attoiconSupport = "tabs-module--attoicon-support--+sQMO";
export var attoiconHelpSupport = "tabs-module--attoicon-help-support--SS-ZJ";
export var attoiconWebTt = "tabs-module--attoicon-web-tt--5jWw0";
export var attoiconArchive = "tabs-module--attoicon-archive--QTUFv";
export var attoiconEmail = "tabs-module--attoicon-email--gm71z";
export var attoiconKiosk = "tabs-module--attoicon-kiosk--HRmUX";
export var attoiconShare = "tabs-module--attoicon-share--rl3wT";
export var attoiconCrew = "tabs-module--attoicon-crew---CfY-";
export var attoiconTeamActivity = "tabs-module--attoicon-team-activity--vuOdM";
export var attoiconTeam = "tabs-module--attoicon-team--bV4fc";
export var attoiconWages = "tabs-module--attoicon-wages--F02al";
export var attoiconNotification = "tabs-module--attoicon-notification--68jvT";
export var attoiconAvatar = "tabs-module--attoicon-avatar--mZs2s";
export var attoiconViewMap = "tabs-module--attoicon-view-map--iymb3";
export var attoiconMovementTracking = "tabs-module--attoicon-movement-tracking--oc0nX";
export var attoiconWageEstimates = "tabs-module--attoicon-wage-estimates--bl5hf";
export var attoiconWageEstimatesBold = "tabs-module--attoicon-wage-estimates-bold--Q+S4j";
export var attoiconClose = "tabs-module--attoicon-close--q7erB";
export var attoiconPlus = "tabs-module--attoicon-plus--irUgK";
export var attoiconMinus = "tabs-module--attoicon-minus--rCmQS";
export var attoiconTick = "tabs-module--attoicon-tick--7nKhV";
export var attoiconArrowLeft = "tabs-module--attoicon-arrow-left--+yIHu";
export var attoiconArrowRight = "tabs-module--attoicon-arrow-right--Uv--C";
export var attoiconArrowDown = "tabs-module--attoicon-arrow-down---JKzX";
export var attoiconArrowUp = "tabs-module--attoicon-arrow-up--Mgr3i";
export var attoiconPlay = "tabs-module--attoicon-play---9VR0";
export var attoiconLongArrow = "tabs-module--attoicon-long-arrow--Kw+Vx";
export var container = "tabs-module--container--2xtad";
export var listContainer = "tabs-module--listContainer--MYdkU";
export var item = "tabs-module--item--9eVBr";
export var isActive = "tabs-module--isActive--il8H0";
export var sideBorder = "tabs-module--sideBorder--9oOuT";