// extracted by mini-css-extract-plugin
export var btnDefault = "service-card-module--btn-default--8q3l7";
export var attoiconJobs = "service-card-module--attoicon-jobs--axj-0";
export var attoiconNotes = "service-card-module--attoicon-notes--kvHTB";
export var attoiconTimesheets = "service-card-module--attoicon-timesheets---ilAT";
export var attoiconTimesheetsRounded1 = "service-card-module--attoicon-timesheets-rounded1--40cMs";
export var attoiconMobileTt = "service-card-module--attoicon-mobile-tt--+N8ce";
export var attoiconNamedSites = "service-card-module--attoicon-named-sites--npwGy";
export var attoiconProfitableBids = "service-card-module--attoicon-profitable-bids--sl-2y";
export var attoiconDepartments = "service-card-module--attoicon-departments--AON5v";
export var attoiconTimeline = "service-card-module--attoicon-timeline--ocVp2";
export var attoiconFilters = "service-card-module--attoicon-filters--BuC22";
export var attoiconVerified = "service-card-module--attoicon-verified--NfOyx";
export var attoiconTimezone = "service-card-module--attoicon-timezone--gq0v7";
export var attoiconManualEntries = "service-card-module--attoicon-manual-entries--6uOYK";
export var attoiconJobCosting = "service-card-module--attoicon-job-costing--IZMVJ";
export var attoiconLocationTracking = "service-card-module--attoicon-location-tracking--Op4h4";
export var attoiconBreak = "service-card-module--attoicon-break--WuF+O";
export var attoiconNightShift = "service-card-module--attoicon-night-shift--nC+H0";
export var attoiconOvertime = "service-card-module--attoicon-overtime--b1AcM";
export var attoiconTimeTracking1 = "service-card-module--attoicon-time-tracking1--7pvnQ";
export var attoiconTimeTracking = "service-card-module--attoicon-time-tracking--ih61H";
export var attoiconLocationHistory = "service-card-module--attoicon-location-history--sSZdi";
export var attoiconPrivacy = "service-card-module--attoicon-privacy--FV3dx";
export var attoiconTimeEntryHistory = "service-card-module--attoicon-time-entry-history--ojPO5";
export var attoiconCustomize = "service-card-module--attoicon-customize--eXu9i";
export var attoiconRoundedClock = "service-card-module--attoicon-rounded-clock--Kf9X4";
export var attoiconTimeOff = "service-card-module--attoicon-time-off--edZme";
export var attoiconNamedLocations = "service-card-module--attoicon-named-locations--R7ky9";
export var attoiconSandClock = "service-card-module--attoicon-sand-clock--n2pFY";
export var attoiconBattery = "service-card-module--attoicon-battery--wgRkA";
export var attoiconSupport = "service-card-module--attoicon-support--pXRcu";
export var attoiconHelpSupport = "service-card-module--attoicon-help-support--Ae-aL";
export var attoiconWebTt = "service-card-module--attoicon-web-tt--tkReq";
export var attoiconArchive = "service-card-module--attoicon-archive--5EUlY";
export var attoiconEmail = "service-card-module--attoicon-email--FCy-6";
export var attoiconKiosk = "service-card-module--attoicon-kiosk--WZ2au";
export var attoiconShare = "service-card-module--attoicon-share--WwuRJ";
export var attoiconCrew = "service-card-module--attoicon-crew--9nmXS";
export var attoiconTeamActivity = "service-card-module--attoicon-team-activity--hzaAh";
export var attoiconTeam = "service-card-module--attoicon-team--slvbz";
export var attoiconWages = "service-card-module--attoicon-wages--o2loM";
export var attoiconNotification = "service-card-module--attoicon-notification--ZfHjF";
export var attoiconAvatar = "service-card-module--attoicon-avatar--AjJ4c";
export var attoiconViewMap = "service-card-module--attoicon-view-map--vRa-l";
export var attoiconMovementTracking = "service-card-module--attoicon-movement-tracking--9IU5c";
export var attoiconWageEstimates = "service-card-module--attoicon-wage-estimates--gOMxj";
export var attoiconWageEstimatesBold = "service-card-module--attoicon-wage-estimates-bold--luZrU";
export var attoiconClose = "service-card-module--attoicon-close--+BM1-";
export var attoiconPlus = "service-card-module--attoicon-plus--ddKQY";
export var attoiconMinus = "service-card-module--attoicon-minus--aZIyC";
export var attoiconTick = "service-card-module--attoicon-tick--su8ka";
export var attoiconArrowLeft = "service-card-module--attoicon-arrow-left--5vHHX";
export var attoiconArrowRight = "service-card-module--attoicon-arrow-right--74WiO";
export var attoiconArrowDown = "service-card-module--attoicon-arrow-down--C9deB";
export var attoiconArrowUp = "service-card-module--attoicon-arrow-up--IttWX";
export var attoiconPlay = "service-card-module--attoicon-play--iWEmm";
export var attoiconLongArrow = "service-card-module--attoicon-long-arrow--S2P1K";
export var container = "service-card-module--container--TW45f";
export var arrowContainer = "service-card-module--arrowContainer--AMar3";
export var iconWrapper = "service-card-module--iconWrapper--SlAQ4";
export var textContainer = "service-card-module--textContainer--ZTO-b";