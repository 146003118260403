// extracted by mini-css-extract-plugin
export var btnDefault = "comment-card-module--btn-default--PnvpN";
export var attoiconJobs = "comment-card-module--attoicon-jobs--CRKfG";
export var attoiconNotes = "comment-card-module--attoicon-notes--aqpjs";
export var attoiconTimesheets = "comment-card-module--attoicon-timesheets--aDgDy";
export var attoiconTimesheetsRounded1 = "comment-card-module--attoicon-timesheets-rounded1--wZFEs";
export var attoiconMobileTt = "comment-card-module--attoicon-mobile-tt--B8r2U";
export var attoiconNamedSites = "comment-card-module--attoicon-named-sites--7GLhp";
export var attoiconProfitableBids = "comment-card-module--attoicon-profitable-bids--4sJYi";
export var attoiconDepartments = "comment-card-module--attoicon-departments--L3ucw";
export var attoiconTimeline = "comment-card-module--attoicon-timeline---hAYi";
export var attoiconFilters = "comment-card-module--attoicon-filters--rEEI8";
export var attoiconVerified = "comment-card-module--attoicon-verified--NreEW";
export var attoiconTimezone = "comment-card-module--attoicon-timezone--GTxxM";
export var attoiconManualEntries = "comment-card-module--attoicon-manual-entries--6qIDU";
export var attoiconJobCosting = "comment-card-module--attoicon-job-costing--w7l7a";
export var attoiconLocationTracking = "comment-card-module--attoicon-location-tracking--Bb-NR";
export var attoiconBreak = "comment-card-module--attoicon-break--BbIwW";
export var attoiconNightShift = "comment-card-module--attoicon-night-shift--rHjb0";
export var attoiconOvertime = "comment-card-module--attoicon-overtime--Tm2+F";
export var attoiconTimeTracking1 = "comment-card-module--attoicon-time-tracking1--bVNur";
export var attoiconTimeTracking = "comment-card-module--attoicon-time-tracking--h5geR";
export var attoiconLocationHistory = "comment-card-module--attoicon-location-history--KVG+8";
export var attoiconPrivacy = "comment-card-module--attoicon-privacy--ZQOGf";
export var attoiconTimeEntryHistory = "comment-card-module--attoicon-time-entry-history--k94nl";
export var attoiconCustomize = "comment-card-module--attoicon-customize--g6cJP";
export var attoiconRoundedClock = "comment-card-module--attoicon-rounded-clock--gCBVV";
export var attoiconTimeOff = "comment-card-module--attoicon-time-off--Y2rXX";
export var attoiconNamedLocations = "comment-card-module--attoicon-named-locations--THnsm";
export var attoiconSandClock = "comment-card-module--attoicon-sand-clock--tHs5k";
export var attoiconBattery = "comment-card-module--attoicon-battery--NbIOh";
export var attoiconSupport = "comment-card-module--attoicon-support--6Au0Z";
export var attoiconHelpSupport = "comment-card-module--attoicon-help-support--hm+cJ";
export var attoiconWebTt = "comment-card-module--attoicon-web-tt--274vu";
export var attoiconArchive = "comment-card-module--attoicon-archive--3KOK8";
export var attoiconEmail = "comment-card-module--attoicon-email--aRVg7";
export var attoiconKiosk = "comment-card-module--attoicon-kiosk--MGUsR";
export var attoiconShare = "comment-card-module--attoicon-share--O1z7d";
export var attoiconCrew = "comment-card-module--attoicon-crew--532YM";
export var attoiconTeamActivity = "comment-card-module--attoicon-team-activity--gOqz1";
export var attoiconTeam = "comment-card-module--attoicon-team--X6B0c";
export var attoiconWages = "comment-card-module--attoicon-wages---5dia";
export var attoiconNotification = "comment-card-module--attoicon-notification--6Xe-u";
export var attoiconAvatar = "comment-card-module--attoicon-avatar--iHX2M";
export var attoiconViewMap = "comment-card-module--attoicon-view-map--KUXCz";
export var attoiconMovementTracking = "comment-card-module--attoicon-movement-tracking--izmBQ";
export var attoiconWageEstimates = "comment-card-module--attoicon-wage-estimates--tXM7+";
export var attoiconWageEstimatesBold = "comment-card-module--attoicon-wage-estimates-bold--GKruh";
export var attoiconClose = "comment-card-module--attoicon-close--CI1lN";
export var attoiconPlus = "comment-card-module--attoicon-plus--NQRFl";
export var attoiconMinus = "comment-card-module--attoicon-minus--CxAbH";
export var attoiconTick = "comment-card-module--attoicon-tick--yNeOg";
export var attoiconArrowLeft = "comment-card-module--attoicon-arrow-left--gK-Nq";
export var attoiconArrowRight = "comment-card-module--attoicon-arrow-right--viC1g";
export var attoiconArrowDown = "comment-card-module--attoicon-arrow-down--ET4ON";
export var attoiconArrowUp = "comment-card-module--attoicon-arrow-up--Py6Cp";
export var attoiconPlay = "comment-card-module--attoicon-play--NXIL2";
export var attoiconLongArrow = "comment-card-module--attoicon-long-arrow--Y7SP7";
export var container = "comment-card-module--container--AX0FX";
export var centeredContent = "comment-card-module--centeredContent--RhnEG";
export var starsWrapper = "comment-card-module--starsWrapper--mGXZA";
export var starsImg = "comment-card-module--starsImg--Dh-EG";
export var content = "comment-card-module--content--ZiW5d";
export var stars = "comment-card-module--stars--a0J8C";
export var authorWrapper = "comment-card-module--authorWrapper--42yg-";
export var profilePicture = "comment-card-module--profilePicture--XPvag";